import React from 'react'
import './Card.css'
export default function Card({
  title, children}) {
  return (
    <div className='card '>
      <div className='cardHeader'>
          <p className='cardTitle'>{title}</p>
      </div>
      <div className='cardBody'>
        {children}
      </div>
    </div>
  )
}
