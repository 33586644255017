import logo from './logo.svg';
import './App.css';
import { range, select } from 'd3'
import Card from './components/Card/Card';
import HeroSection from './sections/HeroSection';
function App() {
  
  
  return (
    <div className="App">
      <HeroSection />
    </div>
  );
}

export default App;
