import React from 'react'
import './HeroSection.css'
import Card from '../../components/Card/Card'
import Row from '../../components/Row/Row'
export default function HeroSection() {
  return (
    <div id='HeroSection'>
        <Lhs />
        <Rhs />
    </div>
  )
}


function Lhs() {
    return (
        <div id='HeroLhs'>
            <div className='HeroLhs_metadata'>
                <p className='title'>Explore our services              </p>
                <p className='subtitle'>Free market monitoring tools</p>
            </div>
        </div>
    )
}

function Rhs() {
    let twiter =[{
        name:  '@CoinSonarBot',
        link: 'https://x.com/CoinSonarBot',
        followers: '27.7k',
        img: '/img/1.jpg'
    },
    {
        name:  '@FishTheWhales',
        link: 'https://x.com/FishTheWhales',
        followers: '52k',
        img: '/img/3.jpg'
    },
    {
        name:  '@CycloneReversal',
        link: 'https://x.com/CycloneReversal',
        followers: '13k',
        img: '/img/2.jpg'
    }]


    let telegram =[{
        name:  '@CoinSonarV2',
        link: 'https://t.me/CoinSonarV2',
        followers: '17k',
        img: '/img/1.jpg'
    },
    {
        name:  '@CoinSonar',
        link: 'https://t.me/CoinSonar',
        followers: '11k',
        img: '/img/4.jpg'
    },
    {
        name:  '@FishTheWhales',
        link: 'https://t.me/FishTheWhales',
        followers: '8k',
        img: '/img/3.jpg'
    },
    {
        name:  '@CycloneReversal',
        link: 'https://t.me/CycloneReversal',
        followers: '3k',
        img: '/img/2.jpg'
    },
    {
        name:  '@CycloneRsi',
        link: 'https://t.me/CycloneRSI',
        followers: '2k',
        img: '/img/2.jpg'
    }
]
    return (
        <div id='HeroRhs'>
            <Card title='Telegram'>
                {
                    telegram.map((item, index) => <Row key={index} {...item}/>)
                }
            </Card>
            <Card title='Twitter'>
            {
                    twiter.map((item, index) => <Row key={index} {...item}/>)
                }
            </Card>
        </div>
    )
}