import React from 'react'
import './Row.css'
export default function Row({
    name,
    link,
    followers,
    img
}) {
    return (
        <a className='Row' href={link}>
            <div className='Row_lhs'>
                <img src={img} alt=''></img>
                <div className='Row_lhs_metadata'>
                    <p className='title'>{name}</p>
                    <p className='smallText'>{link}</p>
                </div>
            </div>
            <div className='Row_rhs'>

                <p className='followers'>followers: {followers}</p>
            </div>
        </a>
    )
}
